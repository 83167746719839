exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-tsx": () => import("./../../../src/pages/about.tsx" /* webpackChunkName: "component---src-pages-about-tsx" */),
  "component---src-pages-blogs-index-tsx": () => import("./../../../src/pages/blogs/index.tsx" /* webpackChunkName: "component---src-pages-blogs-index-tsx" */),
  "component---src-pages-blogs-mdx-fields-slug-tsx-content-file-path-src-content-blog-1-mdx": () => import("./../../../src/pages/blogs/{mdx.fields__slug}.tsx?__contentFilePath=/Users/sanjay/development/proppify/companywebsite/src/content/blog-1.mdx" /* webpackChunkName: "component---src-pages-blogs-mdx-fields-slug-tsx-content-file-path-src-content-blog-1-mdx" */),
  "component---src-pages-blogs-mdx-fields-slug-tsx-content-file-path-src-content-blog-2-mdx": () => import("./../../../src/pages/blogs/{mdx.fields__slug}.tsx?__contentFilePath=/Users/sanjay/development/proppify/companywebsite/src/content/blog-2.mdx" /* webpackChunkName: "component---src-pages-blogs-mdx-fields-slug-tsx-content-file-path-src-content-blog-2-mdx" */),
  "component---src-pages-blogs-mdx-fields-slug-tsx-content-file-path-src-content-blog-3-mdx": () => import("./../../../src/pages/blogs/{mdx.fields__slug}.tsx?__contentFilePath=/Users/sanjay/development/proppify/companywebsite/src/content/blog-3.mdx" /* webpackChunkName: "component---src-pages-blogs-mdx-fields-slug-tsx-content-file-path-src-content-blog-3-mdx" */),
  "component---src-pages-blogs-mdx-fields-slug-tsx-content-file-path-src-content-blog-4-mdx": () => import("./../../../src/pages/blogs/{mdx.fields__slug}.tsx?__contentFilePath=/Users/sanjay/development/proppify/companywebsite/src/content/blog-4.mdx" /* webpackChunkName: "component---src-pages-blogs-mdx-fields-slug-tsx-content-file-path-src-content-blog-4-mdx" */),
  "component---src-pages-contact-us-tsx": () => import("./../../../src/pages/contact-us.tsx" /* webpackChunkName: "component---src-pages-contact-us-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-portfolio-tsx": () => import("./../../../src/pages/portfolio.tsx" /* webpackChunkName: "component---src-pages-portfolio-tsx" */),
  "component---src-pages-services-ai-custom-software-development-tsx": () => import("./../../../src/pages/services/ai-custom-software-development.tsx" /* webpackChunkName: "component---src-pages-services-ai-custom-software-development-tsx" */),
  "component---src-pages-services-custom-software-development-tsx": () => import("./../../../src/pages/services/custom-software-development.tsx" /* webpackChunkName: "component---src-pages-services-custom-software-development-tsx" */),
  "component---src-pages-services-digital-transformation-services-tsx": () => import("./../../../src/pages/services/digital-transformation-services.tsx" /* webpackChunkName: "component---src-pages-services-digital-transformation-services-tsx" */),
  "component---src-pages-services-iot-development-services-tsx": () => import("./../../../src/pages/services/iot-development-services.tsx" /* webpackChunkName: "component---src-pages-services-iot-development-services-tsx" */)
}

